/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PageLoader from './components/PageLoader';
import { Viewport } from './helpers/Viewport';
import Routes from './routes';
import CheckTheme from './helpers/checkTheme';

export default function App() {
  return (
    <div id="app-view-container" className="theme-halloween">
      <React.Suspense
        fallback={
          <div className="w-full h-screen flex items-center">
            <PageLoader />
          </div>
        }
      >
        <Viewport>
          <Routes />
          <CheckTheme />
        </Viewport>
      </React.Suspense>
    </div>
  );
}
